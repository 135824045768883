<template>
  <div class="page-4-item">
    <div class="icon">
      <img :src="this.iconUrl">
    </div>
    <div class="caption">
      <p class="caption-main">{{this.title}}</p>
      <p class="caption-content">{{this.content}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name : "Page4Item",
    props : {
        title : {type : String , required: true},
        iconUrl : {type : String , required: true},
        content  : {type : String , required: true},
    }
  }
</script>

<style scoped>
  .page-4-item{
    width: 240px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .caption{
    text-align: center;
  }

  .caption-main{
    color: #313131;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 1rem;
  }

  .caption-content{
    color: #666;
    font-size: .8rem;
    padding: 3px;
  }

</style>