<template>
  <div class="page-4">
    <div class="title">
      <h2>沙漏验机支持检测的项目</h2>
      <span
        >沙漏验机，全球独创，验机神器，兼容所有iOS设备 iPhone / iPad / iPod
        Touch 能检测手
        机里面多达几十项的内容，为用户达到检测手机真假的效果。</span
      >
    </div>
    <div class="content">
      <page-4-item
        v-for="item in page4ItemData"
        :title="item.title"
        :iconUrl="require(`@/assets/img/${item.iconUrl}`)"
        :content="item.content"/>
    </div>
  </div>
</template>

<script>
import Page4Item from "./Item/Page4Item.vue";
export default {
  name: "Page4",
  components: {
    Page4Item,
  },
  data() {
    return {
      page4ItemData: [
        { title: "序列号", iconUrl: "i_14.png", content: "支持检测序列号是否更改过" },
        { title: "设备型号", iconUrl: "i_15.png", content: "支持检测设备型号是否更改过" },
        { title: "地区号", iconUrl: "i_16.png", content: "支持检测地区号是否更改过" },
        { title: "监管型号", iconUrl: "i_17.png", content: "支持检测监管型号是否更改过" },
        { title: "模型号", iconUrl: "i_18.png", content: "支持检测模型号是否更改过" },
        { title: "主板", iconUrl: "i_13.png", content: "支持检测主板是否正常" },
        { title: "主板序号", iconUrl: "i_23.png", content: "支持检测主板序号是否更改过" },
        { title: "基带", iconUrl: "i_33.png", content: "支持检测基带是否正常" },
        { title: "硬盘", iconUrl: "i_34.png", content: "支持检测硬盘是否有拆机记录 是否为扩容机" },
        { title: "五码匹配", iconUrl: "i_35.png", content: "支持检测五码是否匹配" },
        { title: "WiFi地址", iconUrl: "i_36.png", content: "支持检测WIFI地址是否更改过" },
        { title: "蜂窝地址", iconUrl: "i_37.png", content: "支持检测蜂窝地址是否更改过" },
        { title: "蓝牙地址", iconUrl: "i_38.png", content: "支持检测蓝牙地址是否更改过" },
        { title: "越狱激活", iconUrl: "i_03.png", content: "支持检测设备越狱激活状态" },
        { title: "销售地区与型号", iconUrl: "i_04.png", content: "支持检测销售型号是否更改过" },
        { title: "指纹", iconUrl: "i_06.png", content: "支持检测指纹是否匹配 (蓝指纹、灰指纹、正常指纹)" },
        { title: "液晶显示屏", iconUrl: "i_07.png", content: "支持检测屏幕是否更换过" },
        { title: "摄像头", iconUrl: "i_08.png", content: "支持检测前后摄像头是否更换过" },
        { title: "电池", iconUrl: "i_09.png", content: "支持检测电池是否更换过 支持检测电池是否为组装电池" },
        { title: "更多", iconUrl: "i_10.png", content: "沙漏验机还支持查验更多内容 请下载体验吧" },
      ],
    };
  },
};
</script>

<style scoped>
.page-4 {
  width: 100%;
  height: 1600px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
}

.title{
  margin: 100px 0;
}

.title h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 50px;
  text-align: center;
}

.title span {
  font-size: 1rem;
  color: #555555;
  letter-spacing: 5px;
  text-align: center;
}

.content{
  width: 1250px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
}
</style>